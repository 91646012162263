<template>
	<div>
		<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
			<template slot="toggleTable">
				<a-tabs :activeKey="bound" @change="handleTabChange">
					<a-tab-pane key="0" tab="Pending"> </a-tab-pane>
					<a-tab-pane key="1" tab="Bound"> </a-tab-pane>
				</a-tabs>
			</template>
			<template slot="action" slot-scope="{ columnData }">
				<a-button type="primary" @click="ShowModal(columnData.id)"> Bind to Client </a-button>
			</template>
		</search-list-table>
		<a-modal
			v-drag-modal
			v-model="modal.show"
			title="Bind to Client"
			:destroyOnClose="true"
			width="80%"
			@ok="handModalOk"
			cancelText="Cancel"
			okText="Confirm"
		>
			<a-form-model layout="inline">
				<a-form-model-item>
					<a-input v-model="modal.keyword" placeholder="REG/Mobile/Name" />
				</a-form-model-item>
				<a-form-model-item>
					<a-button type="primary" @click="Search" v-t.preserve="'operate.search2'"></a-button>
				</a-form-model-item>
				<a-table
					style="margin-top: 20px"
					:columns="columns2"
					:rowKey="(record, index) => index"
					:dataSource="dataSource2"
					:pagination="false"
					:row-selection="{
						fixed: true,
						type: 'radio',
						columnWidth: 100,
						columnTitle: this.$t('table.finance.cloums.choose'),
						onChange: ChangeRow,
						selectedRowKeys: selectedRowKeys
					}"
					bordered
				>
					<a-statistic slot="money_slot" slot-scope="text" :precision="2" :value="text === null ? ' - ' : text" />
					<span slot-scope="obj" slot="action">
						<a @click="Detail(obj)">{{ $t('page.checkDetail') }}</a>
					</span>
				</a-table>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
import { apiUpfrontSearchPage, apiUpfrontMatch, apiSearchClient } from '@/api/loan'
import { apiBranchList } from '@/api/branch'
export default {
	data() {
		return {
			branchOfficeList: [],
			bound: '0',
			modal: {
				show: false,
				keyword: '',
				recordId: ''
			},
			columns2: [
				{
					// 案件ID
					title: this.$t('table.finance.cloums.loanId'),
					dataIndex: 'caseId',
					align: 'center',
					width: 150,
					customRender: (v, o) => {
						return o.casePrefix + '-' + o.caseId
					}
				},
				{
					// 用户名
					title: this.$t('table.applyInfo.cloums.userName'),
					dataIndex: 'fullName',
					align: 'center'
				},
				{
					// 联系方式
					title: this.$t('table.applyInfo.cloums.mobile'),
					dataIndex: 'mobile',
					align: 'center',
					customRender: (v) => (v ? v.replaceAll(',', '/') : '')
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center'
				}
			],
			dataSource2: [],
			selectedRows: [],
			selectedRowKeys: []
		}
	},
	computed: {
		formSearchList() {
			return [
				{
					label: 'table.finance.cloum.payID',
					type: 'input',
					validateField: ['paymentId']
				},
				{
					label: 'table.applyInfo.cloums.userName',
					type: 'input',
					validateField: ['name']
				},
				{
					label: 'table.CustomerManagement.fromdata.licensePlateNumber',
					type: 'input',
					validateField: ['registrationNumber']
				},
				{
					label: 'table.applyInfo.loanApplyLabel.branchOffice',
					type: 'select',
					validateField: ['officeId'],
					selectOption: this.branchOfficeList,
					dataPreType: {
						value: 'id',
						text: 'storeName'
					}
				}
			]
		},
		columns() {
			const columnsList =
				this.bound === '0'
					? [
							{
								title: 'No.',
								scopedSlots: { customRender: 'serial_number' },
								width: 100,
								align: 'center'
							},
							{
								title: this.$t('table.finance.cloum.receiptNo'),
								dataIndex: 'paymentId',
								align: 'center',
								width: 160
							},
							{
								title: this.$t('table.finance.cloum.completionTime'),
								dataIndex: 'transactionDate',
								align: 'center'
							},
							{
								title: this.$t('table.finance.cloum.TransAmount'),
								dataIndex: 'amount',
								align: 'center',
								scopedSlots: { customRender: 'money_slot' }
							},
							{
								title: this.$t('table.finance.cloum.payType'),
								align: 'center',
								customRender: (o) => {
									return this.$t(`table.finance.repayment.payTypeMap.${o.type}`)
								}
							},
							{
								title: this.$t('table.finance.cloum.MSISDN'),
								align: 'center',
								dataIndex: 'msisdn'
							},
							{
								title: this.$t('table.applyInfo.cloums.userName'),
								dataIndex: 'fullName',
								align: 'center'
							},
							{
								title: this.$t('table.finance.cloum.billRefNo'),
								dataIndex: 'billRefNumber',
								align: 'center'
							},
							{
								title: this.$t('table.finance.cloum.status'),
								align: 'center',
								fixed: 'right',
								width: 160,
								scopedSlots: { customRender: 'action' }
							}
					  ]
					: [
							{
								title: 'No.',
								scopedSlots: { customRender: 'serial_number' },
								width: 100,
								align: 'center'
							},
							{
								title: this.$t('table.applyInfo.cloums.caseId'),
								dataIndex: 'caseId',
								align: 'center',
								customRender: (v, o) => {
									return o.casePrefix + '-' + o.caseId
								}
							},
							{
								title: this.$t('table.finance.cloum.receiptNo'),
								dataIndex: 'paymentId',
								align: 'center'
							},
							{
								title: this.$t('table.finance.cloum.completionTime'),
								dataIndex: 'transactionDate',
								align: 'center'
							},
							{
								title: this.$t('table.finance.cloum.TransAmount'),
								dataIndex: 'amount',
								align: 'center',
								scopedSlots: { customRender: 'money_slot' }
							},
							{
								title: this.$t('table.finance.cloum.payType'),
								align: 'center',
								customRender: (o) => {
									return this.$t(`table.finance.repayment.payTypeMap.${o.type}`)
								}
							},
							{
								title: this.$t('table.CustomerManagement.fromdata.licensePlateNumber'),
								align: 'center',
								dataIndex: 'registrationNumber'
							},
							{
								title: this.$t('table.applyInfo.cloums.userName'),
								dataIndex: 'fullName',
								align: 'center'
							},
							{
								title: this.$t('table.finance.cloum.billRefNo'),
								dataIndex: 'billRefNumber',
								align: 'center'
							}
					  ]
			return columnsList
		}
	},
	methods: {
		initDataSource(initParams) {
			return apiUpfrontSearchPage({ ...initParams, bound: this.bound })
		},
		ShowModal(recordId) {
			this.modal.show = true
			this.modal.recordId = recordId
		},
		Search() {
			this.selectedRows = []
			this.selectedRowKeys = []
			let params = { keyword: this.modal.keyword }
			if (!params.keyword) {
				this.$message.error('name is required')
				return false
			}
			apiSearchClient(params).then((res) => {
				this.$message.success('search success')
				this.dataSource2 = res
			})
		},
		handModalOk() {
			if (this.selectedRowKeys.length == 0) {
				this.$message.error('Select Empty')
				return false
			}
			const params = [
				{
					loanId: this.selectedRows[0].loanId,
					recordId: this.modal.recordId
				}
			]
			apiUpfrontMatch(params).then((res) => {
				this.modal.show = false
				this.$refs.searchListTable.initTable(1)
			})
		},
		// 查看详情
		Detail(obj) {
			const pageDetail = obj.type === 'disbursed' ? 'lendingdetail' : 'applydetail'
			let routeData = this.$router.resolve({
				name: `menus.customerManagementMenu.${pageDetail}`,
				query: {
					id: obj.loanId
				}
			})
			this.$store.commit('tabs/addTab', {
				name: `menus.customerManagementMenu.${pageDetail}`,
				fullPath: `/home/customerManagement/${pageDetail}?id=${obj.loanId}`
			})
			window.open(routeData.href, '_blank')
			this.$store.commit('tabs/setActiveTab', '/home/finance/upfrontfees')
		},
		ChangeRow(k, r) {
			this.selectedRowKeys = k
			this.selectedRows = r
		},
		handleTabChange(activeKey) {
			this.bound = activeKey
			this.dataSource = []
			this.$refs.searchListTable.initTable(1)
		}
	},
	created() {
		this.$nextTick(() => {
			apiBranchList().then((res) => {
				this.branchOfficeList = res
			})
		})
	}
}
</script>

<style></style>
